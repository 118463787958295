.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff !important;
  border-top: 1px solid #eee;
  padding: 8px 0;
  z-index: 1000;
  border-radius: 0 !important;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #666;
  padding: 5px 15px;
}

.footer-item.active {
  color: #0099ff;
}

.footer-icon {
  font-size: 20px;
  margin-bottom: 4px;
}

.footer-item span {
  font-size: 12px;
  text-align: center;
}

/* Mobile optimization */
@media screen and (max-width: 320px) {
  .footer {
    padding: 5px 0;
  }

  .footer-icon {
    font-size: 18px;
  }

  .footer-item span {
    font-size: 11px;
  }
}

/* Safe area for iPhone X and newer */
@supports (padding: max(0px)) {
  .footer {
    padding-bottom: max(8px, env(safe-area-inset-bottom));
  }
}

/* Active state handling */
.footer-item:hover,
.footer-item:active {
  color: #0099ff;
}
