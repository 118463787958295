/* fleck*/
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fleck-demo {
  width: 100%;
  background-color: var(--background-color);
  background-image: paint(fleck);
}

@supports not (aspect-ratio: 1 / 1) {
  .fleck-demo {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 100%;
  }
}

.fleck-demo[data-theme="login"] {
  --fleck-seed: 12345;
  --fleck-count: 60;
  --fleck-size-base: 10px;
  --fleck-color-1: #58c91bd1;
  --fleck-color-2: #101010;
  --fleck-color-3: #f3f3f3d4;
  background-color: #1a1a83;
  --background-color: #1a1a83;
}

.Loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: url("https://thumbs.gfycat.com/SpryNegligibleLeafcutterant-size_restricted.gif")
    center no-repeat;
  background-size: 60px;
  background-color: rgb(221 221 221 / 36%);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: none;
}
.loginWeapper {
  width: 100%;
  height: 93.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-floating > label {
  padding: 0.3rem 0.75rem !important;
  color: #686868 !important;
}

.form-signin {
  width: 30%;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.992);
  padding: 50px;
  border-radius: 10px;
}
.form-label {
  text-align: start !important;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.form-input {
  border: 1px solid rgb(59, 67, 87) !important;
}
.form-input:focus {
  border: none !important;
}
.is-invalid {
  border: 1px solid rgb(220, 24, 24) !important;
}
.form-check-input {
  border: 1px solid rgb(34, 39, 51) !important;
}
.button-container {
  display: flex;
  justify-content: space-around;
}
.cstm-login-btn {
  border: none !important;
}
.cstm-login-cancel {
  /* margin-left: 55px; */
}
.FotGetPassword {
  text-decoration: none;
  color: var(--primaryColor) !important;
}
.errorMessage {
  color: rgb(255, 81, 0);
  padding-top: 3px;
  margin-bottom: 10px;
  font-size: 16px;
}
.showPassword {
  text-align: right;
  font-size: 13px;
  color: #ddd;
}

@media (max-width: 576px) {
  .form-signin {
    width: 90%;
    padding: 30px;
  }
  .button-container {
    display: flex;
    justify-content: space-around;
  }
}
@media (min-width: 576px) {
  .form-signin {
    width: 60%;
    padding: 30px;
  }
  .button-container {
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .form-signin {
    width: 50%;
    padding: 30px;
  }
  .button-container {
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 992px) {
  .form-signin {
    width: 40%;
    padding: 30px;
  }
  .button-container {
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  .form-signin {
    width: 35%;
    padding: 30px;
  }
  .button-container {
    display: flex;
    justify-content: space-around;
  }
}
@media (min-width: 1400px) {
  .form-signin {
    width: 30%;
    padding: 30px;
  }
  .button-container {
    display: flex;
    justify-content: space-around;
  }
}

/* Base container */
.login-page {
  background: #0099ff;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Add this to contain the wave */
}

/* Wave container - position fixed removed */
.wave-container {
  position: absolute; /* Changed from fixed to absolute */
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 100% 0 0;
  z-index: 1; /* Set a base z-index */
}

@media only screen and (max-width: 575px) {
  .wave-container {
    position: absolute; /* Changed from fixed to absolute */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: #ffffff;
    border-radius: 0 0 0 0;
    z-index: 1; /* Set a base z-index */
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
}

/* Form container - higher z-index */
.login-form-container {
  width: 90%;
  max-width: 360px;
  position: relative;
  z-index: 2; /* Higher than wave container */
  padding: 15px;
  margin: 20px auto;
}

/* Profile icon container */
.profile-icon {
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2; /* Same as form container */
}

.profile-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

/* Title text */
.login-title {
  color: #ffffff;
  text-align: center;
  margin-bottom: 25px;
  font-size: 1.25rem;
  position: relative;
  z-index: 2; /* Same as form container */
}

.custom-input-group {
  margin-bottom: 15px;
  border-bottom: 2px solid #969696;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.custom-input-group svg {
  color: #0099ff;
  margin-right: 10px;
  min-width: 20px;
}

.custom-input-group input {
  border: none;
  background: transparent;
  width: 100%;
  padding: 8px 0;
  outline: none;
  font-size: 16px;
  color: #555555;
}

.btn-login {
  background-color: #0099ff;
  color: white;
  border-radius: 25px;
  padding: 8px 40px;
  border: none;
  width: 200px;
  margin: 0 auto;
  display: block;
  font-size: 16px;
  transition: all 0.3s ease;
}

.btn-login:hover {
  background-color: #007acc;
  transform: translateY(-2px);
}

/* Extra Small devices (phones, 320px and up) */
@media only screen and (min-width: 320px) {
  .login-form-container {
    width: 95%;
    padding: 15px;
  }

  .profile-icon {
    width: 60px;
    height: 60px;
  }

  .custom-input-group input {
    font-size: 14px;
  }

  .btn-login {
    width: 150px;
    font-size: 14px;
  }
}

/* Small devices (phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .login-form-container {
    width: 90%;
    padding: 20px;
  }

  .profile-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .custom-input-group input {
    font-size: 15px;
  }

  .btn-login {
    width: 180px;
    font-size: 15px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login-form-container {
    width: 80%;
  }

  .profile-icon {
    width: 80px;
    height: 80px;
  }

  .profile-icon svg {
    font-size: 40px;
  }

  .custom-input-group input {
    font-size: 16px;
  }

  .btn-login {
    width: 200px;
    font-size: 16px;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login-form-container {
    width: 400px;
  }
}

/* Handle device orientation */
@media screen and (orientation: landscape) and (max-height: 576px) {
  .login-form-container {
    transform: translate(-50%, -45%);
    padding: 10px;
  }

  .profile-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }

  .profile-icon svg {
    font-size: 25px;
  }

  .custom-input-group {
    margin-bottom: 10px;
  }

  .wave-container {
    height: 40vh;
  }
}

/* Touch-friendly improvements */
@media (hover: none) {
  .custom-input-group input {
    font-size: 16px; /* Prevents zoom on iOS */
  }

  .btn-login {
    padding: 12px 40px; /* Larger touch target */
  }
}

/* High DPI screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .custom-input-group {
    border-width: 0.5px;
  }
}

/* Ensure form is usable when zoomed */
@media screen and (max-width: 320px) {
  .login-form-container {
    width: 100%;
    padding: 10px;
  }

  .btn-login {
    width: 140px;
    font-size: 14px;
  }
}

/* Fix for iPhone 5/SE and similar small devices */
@media screen and (max-width: 320px) and (max-height: 568px) {
  .profile-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }

  .custom-input-group {
    padding: 3px 8px;
  }
}

/* Prevent content overflow */
.custom-input-group input {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  .btn-login {
    transition: none;
  }
}
