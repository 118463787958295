.profile-card {
  background: #0099ff;
  padding-top: 3.5rem;
  text-align: center;
  position: relative;
}
.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.settings-section {
  background: white;
  padding: 15px;
  color: #555;
}
.settings-section h6 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: #555;
}
.settings-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.settings-item:last-child {
  border-bottom: none;
}
.settings-icon {
  margin-right: 10px;
}
