/* fleck*/
/* html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fleck-demo {
  width: 100%;
  background-color: var(--background-color);
  background-image: paint(fleck);
}

@supports not (aspect-ratio: 1 / 1) {
  .fleck-demo {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 100%;
  }
}

.fleck-demo[data-theme="green"] {
  --fleck-seed: 123456;
  --fleck-count: 300;
  --fleck-size-base: 7px;
  --fleck-color-1: #518e2c;
  --fleck-color-2: #010101;
  --fleck-color-3: #8e8d8daf;
  --background-color: #14276e;
  background-color: #14276e;
} */
.welcome_title {
  color: #3eff00;
  margin: 10px auto !important;
  width: fit-content !important;
  padding: 5px 30px;
  border-radius: 10px;
}

.client_section {
  width: 100%;
  display: flex;
  /* background-image: linear-gradient(to right, #204b83,#203f6b, #273a60, #2a5693); */
  color: #fff;
  min-height: 100vh;
}
.toggleBar {
  background-color: #ffffffb3 !important;
  z-index: 11 !important;
}
.card-body.client_service_card {
  padding: 1.5rem;
  text-align: center;
  background-image: linear-gradient(to right, #00c6ff, #0072ff);
}

.card-body.client_service_card {
  color: #fff;
}

.card_wraper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.client_wraper {
  position: fixed;
  z-index: 112;
}

ul.client_service_list {
  list-style: none;
  background: #1b2430;
  width: 6rem;
  /* margin-top: 5rem; */
  z-index: 112;
  color: #fff;
  font-weight: 700;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

ul.client_service_list li {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.settings_wraper {
  position: relative;
  transform: translate(-7rem);
  width: 7rem;
}

.show-menu {
  transform: translate(0rem);
  transition: 0.3s;
}

.hide-menu {
  transform: translate(-7rem);
  transition: 0.3s;
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.client_setting_icon {
  position: absolute;
  font-size: 2rem;
  color: #3eff00;
  top: -5%;
  left: 33px;
  cursor: pointer;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  animation: rotating 6s linear infinite;
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
}

.setting_icon_wraper {
  width: 70px;
  height: 70px;
  background-color: #1b2430;
  position: absolute;
  top: 42%;
  left: 60px;
  transform: rotate(44deg);
  border-top-right-radius: 9px;
  z-index: -100;
}

.customer_information {
  padding: 2.5rem, 2.5rem, 0, 0;
}
/* .customer-information-container {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(6, 171, 20, 0.205);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
} */
.customer_information_table {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.menu_icon {
  font-size: 1.5rem;
  color: #3eff00;
}

.client_profile {
  width: 100%;
  margin: 0 auto;
}

table.client_info_table {
  color: #000;
  font-weight: 700;
}

table.client_info_table td {
  padding: 0px 10px !important;
  color: #fff !important;
  font-size: 1.1rem !important;
}

.client_dashboard_section {
  padding: 4rem 0px 2rem 0px;
  margin: auto;
}

.customer_profile_details {
  background-color: #3dff0c29;
  padding: 20px 10px;
  border-radius: 10px;
}
.customer_details span,
.personal_information span,
.customer_profile_details span {
  font-weight: bold;
  margin-left: 10px;
}

span.navHeaderCompany {
  color: #fff;
  font-weight: 700;
  font-family: cursive;
  font-size: 1.5rem;
}

.up_downLoad {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
}

.up_down {
  margin-right: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(18, 22, 27, 0.764);
  color: #3eff00;
  padding: 2rem;
  width: 24rem;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px rgba(238, 238, 238, 0.436);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
.upload-icon-container,
.download-icon-container {
  background-color: rgba(90, 88, 88, 0.591);
  box-shadow: 2px 2px 10px 0px rgb(94, 106, 127);
  padding: 15px;
  border-radius: 50%;
}

.upload-icon,
.download-icon {
  color: rgb(222, 219, 219);
  font-size: 30px;
  font-weight: bold;
}

/* services*/
.service-card {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(1, 1, 1, 0.361);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
}
/* reset password  */

.password_reset_form {
  color: #fff !important;
  margin-top: 1rem;
}

.password_reset_form label {
  color: #fff;
}

.packages_info_wraper {
  width: fit-content;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(27, 36, 48, 0.38);
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.current_package_container {
  width: 40%;
  margin: auto;
}

.packages_info_wraper p {
  margin-right: 0.5rem;
}

.packages_info_wraper p {
  font-weight: 600;
}

.package_table {
  width: 60% !important;
}

.packageList ul li:hover {
  padding: 0.6rem;
  transform: scale(1.1);
}

.package_list_card {
  transition: transform 0.4s;
}
.packageCard {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(1, 1, 1, 0.361);
  box-shadow: 2px 2px 7px 0px rgba(255, 255, 255, 0.405);
  padding: 20px 10px;
  border-radius: 15px;
}
.packageCard:hover {
  background-color: rgba(26, 25, 25, 0.628);
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.405);
}

.restForm-wraper {
  width: 30%;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.507);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
  padding: 30px;
}

.package_list_card:hover {
  transform: scale(1.025);
}

.package_select_button {
  cursor: pointer;
}

.white-bg {
  background-color: #ffffff;
}

.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}

.forum-post-container .media {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
  float: left;
  margin-right: 20px;
  text-align: center;
  width: 110px;
}

.mid-icon {
  font-size: 66px;
}

.forum-item {
  margin: 10px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #696969 !important;
}
.forum-item:last-child {
  border-bottom: none !important;
}

.forum-item small {
  color: #3eff00;
}

.forum-item .forum-sub-title {
  color: #fff;
  margin-left: 50px;
  margin-top: 5px;
  font-weight: 700;
}

.forum-title {
  margin: 15px 0 15px 0;
}

.forum-info {
  text-align: center;
  margin: 0px 5px;
}

.forum-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}

.forum-item-title {
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.forum-item-title:hover {
  color: inherit;
}

.forum-item-title {
  color: #1ab394;
}

.ibox-content {
  color: inherit;
}

.mid-icon {
  font-size: 66px !important;
}
.paymentHistory {
  min-height: 60vh;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(8, 149, 20, 0.332);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
  padding: 60px;
}
.payment_history_list {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
}

.view-payment {
  cursor: pointer;
}

.view-payment button {
  width: 80px;
  border-radius: 10px;
  background-color: #73a3ce;
  border: none;
  color: #fff;
}

.view-payment button:hover {
  color: #000;
  background-color: #ddd;
}

.data-not-found-container {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(6, 171, 20, 0.205);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
  min-height: 54vh;
}
.support-ticket-section,
.supportNumberContainer,
.payment-container {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(8, 149, 20, 0.332);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
  padding: 20px 0px;
}
.supportNumberContainer {
  padding: 10px;
}
.name-space {
  width: 40%;
}
.support-message-form {
  width: 50%;
  display: flex;
  justify-content: center;
}

.support-ticket-table {
  width: 100%;
  margin-top: 1rem;
  border-radius: 5px;
}

.support-table-data,
.payment-table-data {
  display: flex;
  justify-content: space-between;
  background-color: rgba(27, 36, 48, 0.427);
  border-radius: 10px;
}
.support-table-data {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr; /* Adjust columns for desktop */
  grid-gap: 10px;
  padding: 15px;
}
.payment-table-data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.7fr 0.5fr; /* Adjust columns for desktop */
  grid-gap: 10px;
  padding: 15px;
}
.support-table-data > div,
.payment-table-data > div {
  flex-direction: column;
  align-items: flex-start;
}
.payment_history-table tr td {
  padding: 5px !important;
}
.support-table-data > div {
  padding: 5px 10px;
}

.payment_history_list {
  display: flex;
  justify-content: space-around;
  margin-right: 1rem;
}
.support-number-table {
  background-color: rgba(27, 36, 48, 0.638);
  border-radius: 10px;
}

.pageActive {
  background-color: #11d7248b;
}
.pageActive .menu_icon {
  color: #ffffff;
}
.pageActive2 {
  background-color: #11d7248b;
  color: rgb(255, 255, 255) !important;
  border-radius: 10px;
}
.client_navbar {
  position: absolute !important;
  top: 0;
  width: 100%;
  right: 0rem;
  background-color: #1b2430 !important;
}

.client_profile .navbar-nav .nav-link {
  color: #3eff00;
}

.paymentMethod {
  font-size: 3rem;
  text-align: center;
  color: #ff2323;
  cursor: pointer;
  text-transform: capitalize;
}

.paymentMethod-container {
  margin-top: 30px;
  column-gap: 100px;
  row-gap: 30px;
}

.footer {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(6, 171, 20, 0.205);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.405);
  border-radius: 30px 30px 0px 0px;
}
.client_footer {
  display: flex;
  flex-direction: row;
  padding: 2.5rem;
  column-gap: 2%;
}

.ticket_details {
  display: flex;
  /* margin-right: 15px; */
}
.support-ticket-table {
  display: flex;
  flex-direction: column;
}

.section-header {
  width: fit-content;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  column-gap: 20px;
}

@media (max-width: 576px) {
  .client_profile {
    width: 100%;
  }
  .customer_details,
  .personal_information,
  .customer_profile_details {
    width: 100%;
  }
  .customer_information_table {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .client_dashboard_section {
    padding-top: 4rem;
  }

  .restForm-wraper {
    width: 95%;
    margin: 0 auto;
    padding: 30px 20px;
  }

  .up_downLoad {
    flex-direction: column;
  }

  .up_down {
    margin-bottom: 1rem;
    width: 100%;
  }

  .payment_history_list {
    flex-direction: column;
  }

  .support-ticket-table {
    margin: auto;
    width: 100%;
  }

  .support-message-form {
    margin: auto;
    width: 100%;
    margin-bottom: 1rem;
  }

  .support-table-data {
    padding: 0.5rem 0.5rem;
    font-size: 16px;
    justify-content: space-evenly;
  }

  .welcome_title {
    color: #3eff00;
    font-size: 1.2rem;
    margin-top: 5px;
    padding: 5px 10px !important;
  }

  .client_footer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    row-gap: 1rem;
  }

  .packages_info_wraper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    row-gap: 10px;
  }
  .support-table-data,
  .payment-table-data {
    grid-template-columns: 1fr;
  }

  .support-table-data > div,
  .payment-table-data > div {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
  }

  .support_ticket_data,
  .payment_history_data {
    display: grid !important;
    grid-template-columns: minmax(30%, auto) minmax(70%, auto) !important;
    grid-gap: 10px;
    padding: 10px;
  }

  .space {
    display: grid !important;
    grid-template-columns: minmax(45%, auto) minmax(55%, auto) !important;
  }
  .space-2 {
    display: grid !important;
    grid-template-columns: minmax(40%, auto) minmax(60%, auto) !important;
  }
  .support_ticket_data:last-child,
  .payment_history_data:last-child {
    border-bottom: none !important;
  }

  .current_package_container {
    width: 100%;
    margin: auto;
  }
  .change-btn {
    margin-top: 5px;
    border-radius: 10px;
  }
  .paymentHistory {
    min-height: 50vh;
    padding: 20px 10px;
  }
  .name-space {
    width: 50%;
  }
}
@media (min-width: 576px) {
  .customer_details,
  .personal_information,
  .customer_profile_details {
    width: 100%;
  }
  .payment-table-data,
  .support-table-data {
    grid-template-columns: 1fr 1fr; /* Reduce columns for mobile */
    grid-template-rows: repeat(3, auto);
  }

  .support-table-data > div,
  .payment-table-data > div {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }

  .support-table-data > .support-message {
    grid-column: span 2; /* Make the message span across columns */
  }

  .support-table-data > .support-status,
  .support-table-data > .support-apply-date,
  .support-table-data > .support-receive-date {
    grid-column: span 2; /* Span across columns for mobile */
  }

  .support_ticket_data,
  .payment_history_data {
    display: grid !important;
    grid-template-columns: minmax(30%, auto) minmax(70%, auto) !important;
    grid-gap: 10px;
    padding: 10px;
  }
  .support_ticket_data:last-child,
  .payment_history_data:last-child {
    border-bottom: none !important;
  }
  .support_ticket_data:nth-child(3) {
    border-bottom: none !important;
  }
  .restForm-wraper {
    width: 90%;
  }
  .current_package_container {
    width: 90%;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .customer_details {
    width: 40%;
  }
  .personal_information {
    width: 60%;
  }
  .customer_profile_details {
    width: 50%;
  }
  .restForm-wraper {
    width: 60%;
  }
  .current_package_container {
    width: 80%;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .restForm-wraper {
    width: 40%;
  }
  .current_package_container {
    width: 60%;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .information-container {
    column-gap: 20px;
  }
  .customer_details {
    width: 25%;
  }
  .personal_information {
    width: 45%;
  }
  .customer_profile_details {
    width: 25%;
  }
  .support-table-data {
    display: grid;
    grid-template-columns: 1.5fr 3.5fr 1.2fr 1.3fr; /* Adjust columns for desktop */
    padding: 15px;
    grid-gap: 0px;
  }
  .payment-table-data {
    display: grid;
    grid-template-columns: 1.2fr 1.4fr 1.1fr 1.4fr 1.6fr 0.8fr 0.6fr; /* Adjust columns for desktop */
    grid-gap: 0px;
    padding: 15px;
  }
  .support-table-data > div,
  .payment-table-data > div {
    border-bottom: none !important;
  }
  .support_ticket_data,
  .payment_history_data {
    display: grid !important;
    grid-template-columns: minmax(30%, auto) minmax(70%, auto) !important;
    padding: 10px;
  }
  .space {
    display: grid !important;
    grid-template-columns: minmax(45%, auto) minmax(55%, auto) !important;
    padding: 10px;
  }
  .ticket_details {
    display: grid !important;
    grid-template-columns: minmax(15%, auto) minmax(85%, auto) !important;
    /* padding: 10px; */
  }
  .restForm-wraper {
    width: 35%;
  }
  .current_package_container {
    width: 50%;
    margin: auto;
  }
  .eyeFill {
    display: none;
  }
}
@media (min-width: 1400px) {
  .restForm-wraper {
    width: 30%;
  }
  .current_package_container {
    width: 40%;
    margin: auto;
  }
  .eyeFill {
    display: inline-block;
  }
}

.reportAccordion.accordion-button::after {
  width: 0px !important;
}

.client_wraper2 {
  position: fixed;
  z-index: 99999;
  left: 100%;
  right: 0;
  bottom: 31%;
  top: 0;
  transform: translate(50%, 50%);
}

.client_service_list2 {
  list-style: none;
  display: flex;
  flex-direction: column;
  background: #2d86de;
  width: 6rem;
  height: 80%;
  color: #fff;
  font-weight: 700;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow-y: auto;
  scrollbar-width: none;
}

ul.client_service_list2 li {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.client_service_list2 li:hover {
  background-color: #0ea158;
}

.service_wraper {
  width: 7rem;
  height: 80vh;
  overflow: hidden;
}

.settings_wraper2 {
  position: relative;
  transform: translate(-6rem);
  width: 7rem;
  height: 80%;
}

.show-menu2 {
  transform: translate(-6rem);
  transition: 0.3s;
}

.hide-menu2 {
  transform: translate(0rem);
  transition: 0.3s;
}

@-webkit-keyframes rotating

/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.client_setting_icon2 {
  position: absolute;
  font-size: 1.4rem;
  color: rgb(244, 244, 255);
  top: 38px;
  left: 2px;
  /* right: 20px; */
  /* left: 33px; */
  cursor: pointer;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  animation: rotating 6s linear infinite;
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
}

.setting_icon_wraper2 {
  width: 70px;
  height: 70px;
  background-color: #2d86de;
  position: absolute;
  top: 50%;
  right: 60px;
  transform: rotate(44deg);
  border-bottom-left-radius: 9px;
  z-index: -100;
}

.menu_icon2 {
  color: #000;
}

.menu_label2 {
  font-size: 11.5px;
  padding: 0rem 0.2rem;
}

/* @media screen and (max-width: 2100px) {
  
} */

.profile_header {
  height: 2.3rem;
  width: 100%;
  padding: 1rem;
  margin: 1.3rem 0px 0px 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.profileDelete {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: #000000;
  font-size: 1.1rem;
  cursor: pointer;
  margin-right: 2rem;
}

.monthlyBill {
  font-size: 1.2rem;
  padding: 0.4rem 0.3rem;
  margin: 0rem 0rem 0.6rem 0rem;
  font-weight: 600;
  background-color: #1276a705;
  border-radius: 0.2rem;
}

.clintTitle {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background-color: #b1b1b11b;
}

.clintPotos {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border-radius: 50%;
  margin: 0.5rem 1rem;
}

.clintName {
  font-family: Verdana, sans-serif;
  font-size: xx-large;
  font-weight: 100;
}

.bandwithRxTx {
  height: 1.3rem;
  width: 3rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}

.bandwithKbps {
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}

.profileInfo {
  margin: 0.8rem 1rem;
  font-weight: 600;
}

.profileSetting {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px 38px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}

.profileSetting:hover {
  filter: contrast(5) !important;
  background-color: #1276a705;
}

#activeSetting {
  box-shadow: 6px 0px 0px #297fd4 inset;
  filter: contrast(5);
  color: #297fd4;
}

.displayGridManual6_4 {
  display: grid;
  grid-template-columns: minmax(39%, auto) minmax(60%, auto);
  grid-gap: 1%;
  margin: 10px 0;
}

.report5_5,
.displayGridHorizontalFill5_5 {
  display: grid;
  grid-template-columns: minmax(50%, auto) minmax(50%, auto);
}

.displayGridVertical5_5 {
  display: grid;
  grid-template-rows: minmax(48%, auto) minmax(48%, auto);
  grid-gap: 4%;
}

.profile_details {
  height: 21.3rem;
}

.setting_details {
  height: 100vh;
}

.clintSetting {
  border: none !important;
}

.manualLable {
  display: flex;
  justify-content: end;
  color: rgb(131, 131, 131);
}

.forgetPassword {
  display: flex;
  justify-content: start;
  color: #2e87e0;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  margin-top: 2px;
}

.forgetPassword:hover {
  text-decoration: underline;
}

.profileSidebar {
  background-color: aqua;
}

.profileOptions {
  font-size: 18px;
  margin-right: 15px;
  margin-top: -1px;
  padding: 0 0.5rem;
  color: #5e5d5d !important;
}

.options_name {
  display: flex;
  align-self: flex-end;
  font-size: 18px;
  font-weight: 600;
}

.defaultProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0px;
}

.profileAgree {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  gap: 1rem 0px;
  margin-bottom: 1.5rem;
  color: rgb(187, 187, 187);
}

.submitButton {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 1.1rem;
}

.profileDetails {
  font-size: 1rem;
  padding: 0.1rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportAccordion.accordion-button::after {
  width: 0px !important;
}

.reportAccordion.accordion-button:focus,
.reportAccordion.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  outline: none !important;
  color: #000;
}

.reportAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.6rem;
}

.reportName {
  font-size: 18px;
  font-weight: 600;
}

.reportCollect {
  padding: 0.5rem 0.5rem;
  font-size: 16px;
  font-weight: 600;
  background-color: #b1b1b11b;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.collectReport {
  padding: 0.5rem 0.5rem;
  font-size: 15px;
  font-weight: 500;
}

.reportOptions > p {
  display: grid;
  grid-template-columns: minmax(50%, auto) minmax(50%, auto);
  font-size: small;
}

.smsLength_smsType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.smsLengthCount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  font-weight: bold;
}

.smsType {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 515px) {
  #delete_profile {
    display: none;
  }

  #activeSetting {
    box-shadow: 0px 0px 6px #297fd4 inset;
  }

  #clintSetting {
    display: flex;
  }

  #profile_setting {
    height: 4.3rem !important;
  }

  .manualLable {
    justify-content: start;
  }

  .vr_line {
    display: none !important;
  }

  .clintPotos {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }

  .clintName {
    font-family: Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }

  .profileDelete {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-right: 5px !important;
  }

  .close_Btn {
    font-size: 1rem;
  }

  .displayGridManual6_4 {
    display: block;
  }

  .report5_5 {
    display: block;
  }

  .options_name {
    display: none;
  }

  .clint_profile_setting {
    display: none !important;
  }

  .profileSetting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    padding: 5px 0.6rem;
  }

  .profileOptions {
    margin-right: 0px;
  }

  .setting_details {
    height: fit-content;
  }

  .client_details {
    height: 15rem !important;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }

  .details_setting {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 516px) and (max-width: 767px) {
  #delete_profile {
    display: none;
  }

  #activeSetting {
    box-shadow: 0px 0px 6px #297fd4 inset;
  }

  #clintSetting {
    display: flex;
  }

  #profile_setting {
    height: 4.3rem;
  }

  .manualLable {
    justify-content: start;
  }

  .vr_line {
    display: none !important;
  }

  .clintPotos {
    width: 3.3rem;
    height: 3.3rem;
  }

  .clintName {
    font-family: Verdana, sans-serif;
    font-size: larger;
    font-weight: 100;
  }

  .profileDelete {
    display: flex;
    align-items: center;
    font-size: inherit;
    margin-right: 5px !important;
  }

  .close_Btn {
    font-size: 1.1rem;
  }

  .displayGridManual6_4 {
    display: block;
  }

  .options_name {
    display: none;
  }

  .clint_profile_setting {
    display: none !important;
  }

  .profileSetting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    padding: 5px 1.3rem;
  }

  .profileOptions {
    margin-right: 0px;
  }

  .setting_details {
    height: fit-content;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }

  .details_setting {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #delete_profile {
    display: none;
  }

  #activeSetting {
    box-shadow: 0px 0px 6px #297fd4 inset;
  }

  #clintSetting {
    display: flex;
  }

  #profile_setting {
    height: 4.3rem;
  }

  .manualLable {
    justify-content: start;
  }

  .vr_line {
    display: none !important;
  }

  .clintPotos {
    width: 3.8rem;
    height: 3.8rem;
    object-fit: cover;
    border-radius: 50%;
    margin: 0.5rem 1rem;
  }

  .clintName {
    font-family: Verdana, sans-serif;
    font-size: larger;
    font-weight: 100;
  }

  .profileDelete {
    display: flex;
    align-items: center;
    font-size: inherit;
    margin-right: 5px !important;
  }

  .close_Btn {
    font-size: 1.1rem;
  }

  .displayGridManual6_4 {
    display: block;
  }

  .options_name {
    display: none;
  }

  .clint_profile_setting {
    display: none !important;
  }

  .profileSetting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    padding: 5px 1.5rem;
  }

  .profileOptions {
    margin-right: 0px;
  }

  .setting_details {
    height: fit-content;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }

  .details_setting {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .vr_line {
    display: none !important;
  }

  .smsLengthCount {
    display: block;
  }

  .smsType,
  .smsLength_smsType {
    display: block;
    justify-content: start;
    grid-gap: 10px;
  }
}

.magantaColor {
  border: solid 2px #85150d;
}

.support_team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
}

.support_person {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}

.support_info {
  display: flex;
  flex-direction: column;
}

.support_icon {
  font-size: 1.1rem;
}

.border-dotted {
  border: 2px dotted #8c7454; /* ডটেড বর্ডার */
  border-radius: 5px;
  padding: 5px;
}

.ticket-card {
  background: #f8f8f8;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 10px;
}
.dashed-box {
  display: flex;
  flex-direction: column;
}
.ticket-message {
  position: relative;
  padding: 10px;
  background: white;
  border: 2px dashed #8c7454;
  border-radius: 5px;
}
.ticket-message-title {
  position: absolute;
  top: -12px;
  left: 10px;
  background: #e3e2e2;
  padding: 2px 6px;
  border-radius: 5px;
  font-weight: bold;
}
.chip {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0 0 0 15px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}
.status-resolve {
  background: #28a745;
} /* Success */
.status-development,
.status-processing {
  background: #17a2b8;
} /* Info */
.status-pending {
  background: #ffc107;
} /* Warning */
.status-onhold {
  background: #dc3545;
} /* Error */
