@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --primaryColor: #328eea;
  --seconderyColor: #f07f1f;
  /* --lightFront: white;
  --darkFront: rgb(38, 46, 39);
  -titlecolor: rgb(243, 243, 243); */
}

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}
body,
html {
  scroll-behavior: smooth;
}
body {
  color: #77828d;
}
a {
  text-decoration: none;
}

.dropdown-item {
  margin-bottom: 10px !important;
}
.form-select {
  max-width: none !important;
  margin: initial !important;
}
label {
  font-weight: 500;
  color: rgb(29, 28, 28);
  padding-right: 10px;
}
.table {
  --bs-table-striped-color: inherit !important;
  color: inherit !important;
}
td {
  font-size: 1rem !important;
  padding: 10px !important;
}
th {
  font-size: 1rem !important;
}
p {
  margin-bottom: 0px !important;
}
.btn-success {
  background-color: #0eb96a !important;
}
.btn-success:hover {
  background-color: #0d9c5a !important;
}
.page-item.active .page-link {
  background-color: #05d36a !important;
  border-color: #11b725 !important;
}
.page-item.active .page-link {
  color: #fff !important;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgb(218, 218, 218);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(218, 218, 218);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgb(218, 218, 218);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(218, 218, 218);
}

.w-140 {
  width: 140px !important;
}

.w-110 {
  width: 100px !important;
}

.w-6rem {
  width: 6rem;
}

.w-200 {
  width: 200px !important;
}

#max-none {
  max-width: none !important;
}

.mx-content {
  width: max-content;
}

/* Reload Button */
.reloadBtn {
  border-radius: 7px;
  color: var(--primaryColor);
  background: white;
  width: 36px;
  height: 34px;
  margin-left: 12px;
  margin-top: 2px !important;
  transition: 0.3s;
  cursor: pointer;
}

.reloadBtn:hover {
  background: #0ea158;
  color: white;
}
.loader_style {
  width: 20px;
  margin-bottom: 6px;
}

.arrowClock {
  font-size: 1.4rem;
  margin-bottom: 0.3rem;
}

.cardCollapse {
  background: linear-gradient(0deg, #328eea, #297fd4);
  width: max-content;
}

.see_more {
  color: rgb(158, 33, 33);
  cursor: pointer;
}

.see_more:hover {
  color: #328eea;
}

.dashboardData {
  font-size: 15px;
  padding-top: 10px;
  color: #ffffff !important;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.dashboardData:hover {
  color: #2c8beb !important;
}

.dashboardUnpaid {
  font-size: 15px;
  padding-top: 10px;
  color: #ffffff !important;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}
.dashboardUnpaid:hover {
  color: #035c27 !important;
}

.dashboardCollection {
  font-size: 15px;
  padding-top: 0;
  color: #ffffff !important;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}
.dashboardCollection:hover {
  color: #331bd3 !important;
}

.dashboardActive {
  color: #ffffff !important;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}
.dashboardActive:hover {
  color: #2c8beb !important;
}

#custom-form-select .form-select {
  margin-top: 1rem !important;
}

.reseller-summary-count {
  cursor: pointer;
}

.reseller-summary-count:hover {
  color: #05d36a !important;
  text-decoration: underline;
}

.fs-13 {
  font-size: 13px !important;
}

.customerDisable {
  background: #efefef;
  width: fit-content;
  height: 38px;
  padding: 5px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.displayGrid {
  display: grid;
  grid-gap: 10px 10px;
}

.displayGrid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-gap: 10px;
}

.displayGrid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

@media only screen and (max-width: 438px) {
  /* #global_name_options {
    flex-direction: column;
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
  }

  #options_collapse {
    height: 1.8rem !important;
    display: flex;
    justify-content: flex-end;
  }

  .component_name {
    font-size: 1.3rem;
  }

  .addcutmButton {
    width: 1.8rem !important;
    height: 1.6rem !important;
    padding: 0.4rem !important;
    color: var(--primaryColor);
    background: white;
    border-radius: 0.3rem !important;
    margin-left: 0.4rem !important;
    transition: 0.3s;
    cursor: pointer;
  }

  .reloadBtn {
    width: 1.8rem !important;
    height: 1.6rem !important;
    padding: 0.4rem !important;
    color: var(--primaryColor);
    background: white;
    border-radius: 0.3rem !important;
    margin-left: 0.4rem !important;
    transition: 0.3s;
  }

  .loader_style {
    width: 0.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .arrowClock {
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .options_arrow {
    width: 1.8rem !important;
    height: 1.6rem !important;
  } */
}

/* .addcutmButton {
  width: 2.3rem;
  height: 2.1rem;
  padding: 0.5rem;
  color: var(--primaryColor);
  background: white;
  border-radius: 0.4rem;
  margin-left: 0.8rem;
  transition: 0.3s;
  cursor: pointer;
} */

@media only screen and (max-width: 575px) {
  #toggle_selection {
    display: none !important;
  }

  #custom_component_data {
    display: none !important;
  }

  .allData {
    background: rgb(0 0 0 / 13%);
    border-radius: 21px;
    font-size: 16px;
    margin-left: 5px;
    padding: 2px 15px;
  }

  .displayGrid1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid5 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  #custom_component_data {
    display: none !important;
  }

  .displayGrid3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid5 {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid6 {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #custom_component_data {
    display: none !important;
  }

  .customer_component {
    display: none !important;
  }

  .displayGrid3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid6 {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .displayGrid3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid5 {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-gap: 10px;
  }

  .displayGrid6 {
    display: grid;
    grid-template-columns: repeat(5, 1fr) !important;
    grid-gap: 10px;
  }
}

.Loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: url("https://thumbs.gfycat.com/SpryNegligibleLeafcutterant-size_restricted.gif")
    center no-repeat;
  background-size: 60px;
  background-color: rgb(221 221 221 / 36%);
  backdrop-filter: blur(3px);
  z-index: 100;
  display: flex; /* Change from 'none' to 'flex' */
  justify-content: center;
  align-items: center;
}

/* Initially hide it */
.hidden {
  display: none;
}
